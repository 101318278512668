/**
 * Mixin file 
 * All our custom mixins
 *
 * @package buro been
 */


//make flex rows a bit easier:
@mixin flex-row( $equalize:stretch ){
    @include display(flex);
    @include flex-direction(row);
    @include align-items($equalize);
}

//make flex columns a bit easier:
@mixin flex-col( $equalize:stretch ){
    @include display(flex);
    @include flex-direction(column);
    @include align-items($equalize);
}



/* Boxsizing shortcut: */
@mixin borderbox(){
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


/* Transform shortcuts */

// rotate
@mixin rotate( $deg ) {
  @include transform( rotate( #{$deg}deg ) );
}
 
// scale
@mixin scale( $scale ) {
   @include transform( scale( $scale ) );
} 

// translate
@mixin translate( $x, $y ) {
   @include transform( translate( $x, $y ) );
}

// skew
@mixin skew( $x, $y ) {
   @include transform( skew( #{$x}deg, #{$y}deg ) );
}

//transform origin
@mixin transform-origin( $origin ) {
    moz-transform-origin: $origin;
       -o-transform-origin: $origin;
      -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
          transform-origin: $origin;
}


/* Styling placeholders: */
@mixin placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}


/* Retina images: */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}


/* "Hard" gradients: */
@mixin gradient-hard( $start, $end:#fff, $direction:"horizontal" ) {

  @if $direction == "horizontal"{

    @include gradient-hard-horizontal( $start, $end );

  }@else{

    @include gradient-hard-vertical( $start, $end );

  }

}

@mixin gradient-hard-horizontal( $from, $to ) {

  background: $to;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%,$from), color-stop(50%,$from), color-stop(50%,$to), color-stop(100%,$to));
  background: -webkit-linear-gradient(left, $from 0%,$from 50%,$to 50%,$to 100%);
  background:    -moz-linear-gradient(left, $from 0%, $from 50%, $to 50%, $to 100%);
  background:     -ms-linear-gradient(left, $from 0%,$from 50%,$to 50%,$to 100%);
  background:      -o-linear-gradient(left, $from 0%,$from 50%,$to 50%,$to 100%);
  background:         linear-gradient(to right, $from 0%,$from 50%,$to 50%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

@mixin gradient-hard-vertical( $from, $to ) {

  background: $to;
  background: -webkit-gradient(linear, top top, right top, color-stop(0%,$from), color-stop(50%,$from), color-stop(50%,$to), color-stop(100%,$to));
  background: -webkit-linear-gradient(top, $from 0%,$from 50%,$to 50%,$to 100%);
  background:    -moz-linear-gradient(top, $from 0%, $from 50%, $to 50%, $to 100%);
  background:     -ms-linear-gradient(top, $from 0%,$from 50%,$to 50%,$to 100%);
  background:      -o-linear-gradient(top, $from 0%,$from 50%,$to 50%,$to 100%);
  background:         linear-gradient(to bottom, $from 0%,$from 50%,$to 50%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );

}

/* REM font sizes: */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin break-container(){
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin button(){
  background: $black;
  color: #FFF;
  padding: 10px 18px;
  display: inline-block;
  border-radius:$border-radius;
  position: relative;
  transition: .3s;
  color:#FFF;
  text-decoration:none;
  @include font-size( 16px );
  &:hover, &:focus{
    background:$grey;
    color:$black;
    outline:none;
  }
}

@mixin remove-element(){
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}