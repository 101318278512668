/**
 * Variable file
 * place global variables here:
 */

 //@import url("https://use.typekit.net/ajr2rqm.css");

$font-stack: 'open-sans', sans-serif;
$head-stack: 'open-sans-condensed', sans-serif;
$CaflischScript: 'caflisch-script-pro', sans-serif;

$light-weight:300;
$regular-weight:400;
$bold-weight:700;

$wit: #FFFFFF;
$bg: #F7F7F7;
$rood: #DC0933;
$zwart: #2F2C2B;
$oranje: #E94E1B;
$grijs: #877A75;
$witGrijs: #ECEAE6;
$lichtGrijs: #AFA9A6;
$donkerGrijs: #4E4744;
