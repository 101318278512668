@import 'base/_variables';
@import 'base/_mixins';

header {
  position: fixed;
  z-index: 999;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  width: 100%;
  background: none;
  transition: .3s;
  flex-flow: row wrap;
  padding: 0 0;
  top: 0;

  .container {
    padding: 0 0;
    width: 100%;
    position: relative;
    transition: .3s;
    background-color: rgba($wit, .8);

    .topmenu {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 4%;
      display: flex;
      justify-content: flex-end;
      background-color: $zwart;

      .inner-wrapper {
        width: 100%;
        max-width: 100%;
        margin-left: auto;
        margin-right: 0;
        padding: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $wit;
        @include font-size (16px);

        p {
          color: $wit;
          @include font-size (16px);
        }

        a, a:visited {
          color: $oranje;
          @include font-size (16px);
          margin: 0 0 0 0;
          padding: 0 5px;
          line-height: 130%;
          font-weight: $regular-weight;

          &:hover {
            color: $grijs;
          }
        }
      }
    }

    .inner-wrapper {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 4%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        padding: 0;
        text-decoration: none;
        width: 100%;
        max-width: 235px;
        margin: 10px 0 0 0;
        display: block;
        transition: .3s;
      }

      .main-nav {
        text-align: right;
        display: block;
        width: 100%;
        max-width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start; //center;
        position: relative;

        ul {
          padding-left: 0;
          list-style:none;
          display: flex;
          width: 100%;
          max-width: 800px; //1024px;
          margin-left: 80px;

          li {
            position: relative;
            display: flex;
            align-items: center;

            a, a:visited {
              //display: inline-block;
              text-decoration:none;
              transition: .3s;
              line-height:1;
              position: relative;
              color: $grijs;
              position: relative;
              font-family: $font-stack;
          		font-style: normal;
          		font-weight: $bold-weight;
              @include font-size (18px);
              position: relative;
              margin-left: 45px;

              &:after {
                display: block;
                content: "";
                height: 4px;
                background-color: $oranje;
                width: 0;
                position: absolute;
                bottom: -12px;
                transition: .3s;
                left: 50%;
                margin-left: -15px;
                opacity: 0;
                border-radius: 5px;
              }

              &:hover {
                &:after {
                  width: 30px;
                  opacity: 1;
                }
              }
            }

            &.current_page_item {
              a, a:visited {
                color: $oranje;


                &:after {
                  width: 30px;
                  opacity: 1;
                }
              }
            }

            &:first-child {
              margin-left: 0;
            }

            ul.sub-menu {
              position: absolute;
              left: 0px;
              top: 20px;
              padding-left: 0;
              padding-top: 10px;
              padding-bottom: 10px;
              width: 100%;
              background: $oranje;
              transition: .8s;
              opacity: 0;
              display: none;
              border-radius: 5px;
              margin-left: 0;

              li {
                width: 100%;

                transition: .3s;
                margin-left: 0;

                a, a:visited {
                  padding: 15px 30px !important;
                  width: 100%;
                  text-align: left;
                  white-space: nowrap;
                  //font-size: 14px;
                  color: rgba($wit, 1);
                  transition: .3s;
                  margin-left: 0;

                  &:before, &:after {
                    display: none;
                  }

                  &:hover {
                    color: $donkerGrijs;
                  }
                }
              }
            }

            //&.menu-cta {
            &.btn-direct-zorg-aanvragen {
              a, a:visited {
                position: relative;
          		  transition: .3s;
          		  text-decoration: none;
          			font-family: $head-stack;
                font-weight: $bold-weight;
          			@include font-size (18px);
                background-color: #1B6AE9; //$oranje;
          			color: $wit;
          			//border: 2px solid $oranje;
          			border-radius: 10px;
          			padding: 19px 16px;

                &:after {
                  display: none;
                }

          			&:hover {
                  background-color: rgba($oranje, 1);
          				//border: 2px solid $wit;
          				color: $wit;

                  &:after {
                    display: none;
                  }
          			}
              }
            }

            &:first-child {
              a, a:visited {
                margin-left: 0;
              }
            }
          }
        }

        // ul li.menu-item-has-children > a:before {
        // content: "\f107";
        // font-family: "Font Awesome 5 Free";
        // font-weight: 900;
        // color: $zwart;
        // font-size: 10px;
        // position: absolute;
        // top: 20px;
        // right: -10px;
        // }

        ul li:hover > ul {
        display: table; //block; /* show sub menus when hovering over a parent */
        opacity: 1;
        }
      }
    }
  }

  &.scroll-version, &.background-version {
    .container {
      background-color: rgba($wit, 1);
    }
  }
}

.mobile-nav {
    top: 0px;
    height: 100vh; //calc(100vh - 70px);
    overflow-y: scroll;
    width: 100%;
    position: fixed;
    right: 0%;
    left: 0;
    padding: 0;
    opacity: 0;
    transition:.3s;
    z-index: 900;
    text-align:center;
    pointer-events:none;
    display: flex;
    align-items: flex-start;
    padding-top: 120px;

    ul {
        list-style: none;
        display: block;
        padding-left: 0;
        width: 100%;

        li{
            display: block;
            width: 100%;

            a, a:visited{
                width:100%;
                display: block;
                padding: 20px;
                text-decoration:none;
                color: $wit;
            }
        }
    }

    &.fold-out {
        opacity:1;
        pointer-events:auto;
        display: flex;
    }
}

.menu-switch {
    height: 44px;
    width:44px;
    text-align:center;
    margin-left: 30px;
    border-radius: 0;
    z-index: 100;
    display: none;

    i {
        color: $donkerGrijs;
        @include font-size (40px);
        position:relative;
        z-index: 2;
        text-align: center;
        margin: 0 auto;
        height: 44px;
        width:44px;
    }
}

.background-wrapper{
    position:fixed;
    z-index: 800;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:none;
    pointer-events:none;
    opacity:0;
    background: rgba($oranje, 1);
    transition: .3s;
    display:none;

    &.active-bg{
        opacity:1;
        pointer-events:auto;
        display:block;
    }
}
